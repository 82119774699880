import React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
// import { graphql } from "gatsby"
// import AllBlogs from "../../components/AllBlogs"

const BlogsPage = ({ data }) => {
  return (
    <Layout classNameNav="bg-green-700 text-white">
      <Seo
        title="Syawal Jasira Blog Page of Personal and Portfolio Website"
        siteUrl="https://www.syawaljasira.com/blog"
      />
      <div className="h-screen flex mx-auto justify-center items-center">
        <p className="text-2xl text-green-900">
          Mohon maaf saat ini halaman blog belum tersedia.
        </p>
      </div>
    </Layout>
  )
}

// export const pageQuery = graphql`
//   query AllBlogsPost {
//     allMdx(
//       sort: { fields: frontmatter___date, order: DESC }
//       filter: { frontmatter: { header: { eq: "blog" } } }
//     ) {
//       edges {
//         node {
//           frontmatter {
//             date(formatString: "MMMM DD, YYYY")
//             title
//           }
//           id
//           slug
//           body
//         }
//       }
//     }
//   }
// `

export default BlogsPage
